var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: `단위작업: ${_vm.hazard.sopName}`,
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.hazard.heavyWorkHazards,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
          },
          on: { innerBtnClicked: _vm.innerBtnClicked },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "btn" && props.row.editFlag !== "C"
                    ? [
                        _c("q-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editable && !_vm.disabled,
                              expression: "editable && !disabled",
                            },
                          ],
                          staticStyle: { "font-weight": "700" },
                          attrs: {
                            dense: "",
                            icon: "delete",
                            color: "negative",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteHazard(props.row, col)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && !_vm.disabled,
                        expression: "editable && !disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.saveData,
                      mappingType: "PUT",
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveHazard,
                      btnCallback: _vm.saveHazardCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }